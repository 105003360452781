import { moreInfoData } from "../../mockdata";
import { MoreInfoData } from "../primitives/moreInfoData";

export const MoreInfo = () => {
  return (
    <div className="lg:max-w-[798px] text-center space-y-16 md:mx-8 mx-5  lg:mx-20 ">
      <p className="lg:text-xl text-base font-normal text-white  ">
        We create and build thoughtful digital experiences for the modern world.
        As a full-service digital agency, we work closely with our clients to
        define, design and develop transformative user experiences across all
        platforms and brand’s touchpoints.
      </p>
      <div className="grid md:grid-cols-3 md:divide-x grid-cols-1  gap-5  md:gap-0">
        {moreInfoData.map((data, index) => (
          <MoreInfoData
            key={index}
            infoData={data.infoData}
            infoDescription={data.infoDescription}
          />
        ))}
      </div>
    </div>
  );
};
