import { Header } from "../components/connected/header/header";
import { BlockText } from "../components/primitives/blockText";
import { ContactForProjects } from "../components/primitives/contactForProjects";
import { Footer } from "../components/sections/footer";
import Pic1 from "../assets/imgs/bigProject1.png";
import Pic2 from "../assets/imgs/bigProject2.png";
import Pic3 from "../assets/imgs/bigProject3.png";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";
import { ProjectBlockText } from "../components/primitives/projectBlockText";

const Project = () => {
  return (
    <main>
      <div className="xl:mx-20 mx-5 md:mx-8 ">
        <Header variant="black" />
      </div>
      <section className="flex justify-center text-center border-t border-gray-200 lg:py-24  py-10">
        <div className="lg:space-y-5 space-y-3 max-w-[768px]">
          <p className="lg:text-xl text-base text-gray-600">Devlopment</p>
          <h2 className="lg:text-6xl font-bold text-3xl">Project Name</h2>
        </div>
      </section>
      <section className="lg:mx-20 ">
        <div className="flex justify-between pb-10 lg:pb-24  px-8">
          <div className=" hidden lg:flex flex-col space-y-6">
            <div className="relative w-[568px]   h-[390px] ">
              <img src={Pic1} alt="project" className=" w-full h-full" />
            </div>
            <div className="relative w-[568px]   h-[390px] ">
              <img src={Pic2} alt="project" className=" w-full h-full" />
            </div>
            <div className="relative w-[568px]   h-[390px] ">
              <img src={Pic3} alt="project" className=" w-full h-full" />
            </div>
          </div>
          <div className=" max-w-[568px] ">
            <div className="space-y-11">
              <BlockText
                title="overview"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa tortor, integer ultricies augue volutpat mi et. Vel etiam convallis et augue aliquam."
              />
              <BlockText
                title="task"
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus risus arcu, tincidunt feugiat eu morbi eget accumsan in. Urna sed sit malesuada proin cras viverra. Mauris enim lacus, euismod amet mattis tellus a elit. Iaculis congue in lacus ultricies sapien, fermentum. Dictum lorem penatibus dolor, sed velit at malesuada lacus malesuada."
              />
              <div>
                <a
                  href="https://www.google.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="bg-gray-900 flex space-x-3 font-medium text-white py-3 px-5 rounded-lg">
                    <p>View live project</p>
                    <span>
                      <ArrowLongRightIcon className="text-white w-6 h-6 " />
                    </span>
                  </button>
                </a>
              </div>
              <div className="border-t border-gray-200 py-[44px]">
                <div className="grid grid-cols-2 gap-6">
                  <ProjectBlockText text="23 July, 2020" title="DATE" />
                  <ProjectBlockText
                    text="17 Riverwood Drive, Central Califirnia,USA"
                    title="client"
                  />
                  <ProjectBlockText text="Development" title="Project Type" />
                  <ProjectBlockText text="2 Months 18 Days" title="duration" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lg:pb-24 pb-10 lg:mx-28 mx-8">
        <ContactForProjects
          email="support@grapevineinnovate.com"
          telNo="+2348185466356"
        />
      </section>
      <Footer />
    </main>
  );
};

export default Project;
