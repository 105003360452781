import { Header } from "../components/connected/header/header";
import { Footer } from "../components/sections/footer";

const WorkWithUs = () => {
  return (
    <main>
      <section className="bg-blue-50 xl:px-20 px-5 md:px-8  lg:pb-24 pb-16 lg:space-y-24 space-y-16 ">
        <Header variant="black" />
        <div className="flex justify-center text-center">
          <div className="lg:space-y-5 space-y-3 max-w-[768px]">
            <h2 className="lg:text-6xl font-bold text-3xl">Work with us!</h2>
            <p className="lg:text-xl text-base text-gray-600">
              To extend our skilled teams, we don't just look for experts with
              great technical backgrounds. We look for people with excellent
              soft skills, inquiring minds, and great aspiration for
              self-perfection and constant development.
            </p>
          </div>
        </div>
      </section>
      <section className="xl:px-20 px-5 md:px-8  lg:py-24 py-16 ">
        <div className="flex justify-center text-center">
          <div className="lg:space-y-5 space-y-3 max-w-[768px]">
            <h2 className="lg:text-6xl font-semibold text-3xl">Our Openings</h2>
            <p className="lg:text-xl text-base text-gray-600">
              Sorry, we do not have any openings at the moment. Be sure to check
              back very soon.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default WorkWithUs;
