export interface IBlockTextProps {
  title: string;
  text: string;
}
export const BlockText = ({ title, text }: IBlockTextProps) => {
  return (
    <div className="space-y-4">
      <h6 className="font-semibold text-3xl capitalize">{title}</h6>
      <p className="text-lg text-gray-600">{text}</p>
    </div>
  );
};
