import {
  DesignIcon,
  DevelopmentIcon,
  DevopsIcon,
  DigitalIcon,
} from "../assets/icons";
import Project_1 from "../assets/imgs/project_1.png";
import Project_2 from "../assets/imgs/project_2.png";
import Project_3 from "../assets/imgs/project_3.png";
import Pic1 from "../assets/imgs/pic1.png";
import Pic2 from "../assets/imgs/pic2.png";
import Pic3 from "../assets/imgs/pic3.png";

export const whatWeDoCardInfo = [
  {
    icon: <DesignIcon />,
    title: "design",
    desciption:
      "A new website in the style of your company? Or do you prefer completely new visual identity? We bring out the unique style of your company.",
  },
  {
    icon: <DevelopmentIcon />,
    title: "developemnt",
    desciption:
      "Your new website, application or internal platform? Our developers create the very best digital solution for your company.",
  },
  {
    icon: <DevopsIcon />,
    title: "devops",
    desciption:
      "We design, oversee and control your operational processes to ensure that resources are right-sized to serve business workloads in a cost-effective manner.",
  },
  {
    icon: <DigitalIcon />,
    title: "Digital Marketing & Strategy",
    desciption:
      "Our online advertisements, email campaigns, social media services would help attract your business to its target audience.",
  },
];

export const ourworkCardInfo = [
  {
    projectImg: Project_1,
    projectType: "development",
    projectName: "project name",
  },
  {
    projectImg: Project_2,
    projectType: "development",
    projectName: "project name",
  },
  {
    projectImg: Project_3,
    projectType: "development",
    projectName: "project name",
  },
];

export const moreInfoData = [
  {
    infoData: "10+",
    infoDescription: "Team Strenght",
  },
  {
    infoData: "7 years",
    infoDescription: "Experience",
  },
  {
    infoData: "4k",
    infoDescription: "global customers",
  },
];

export const allworkCardInfo = [
  {
    projectImg: Pic1,
    projectType: "development",
    projectName: "project name",
  },
  {
    projectImg: Pic2,
    projectType: "design",
    projectName: "project name",
  },
  {
    projectImg: Pic3,
    projectType: "devops",
    projectName: "project name",
  },
  {
    projectImg: Pic2,
    projectType: "design",
    projectName: "project name",
  },
  {
    projectImg: Pic3,
    projectType: "devops",
    projectName: "project name",
  },
  {
    projectImg: Pic1,
    projectType: "development",
    projectName: "project name",
  },
  {
    projectImg: Pic3,
    projectType: "devops",
    projectName: "project name",
  },
  {
    projectImg: Pic2,
    projectType: "design",
    projectName: "project name",
  },
  {
    projectImg: Pic1,
    projectType: "development",
    projectName: "project name",
  },
];
export const developmentCardInfo = [
  {
    projectImg: Pic1,
    projectType: "development",
    projectName: "project name",
  },
  {
    projectImg: Pic1,
    projectType: "development",
    projectName: "project name",
  },
  {
    projectImg: Pic1,
    projectType: "development",
    projectName: "project name",
  },
];

export const devOpsCardInfo = [
  {
    projectImg: Pic3,
    projectType: "devops",
    projectName: "project name",
  },
  {
    projectImg: Pic3,
    projectType: "devops",
    projectName: "project name",
  },
  {
    projectImg: Pic3,
    projectType: "devops",
    projectName: "project name",
  },
];
export const designCardInfo = [
  {
    projectImg: Pic2,
    projectType: "design",
    projectName: "project name",
  },
  {
    projectImg: Pic2,
    projectType: "design",
    projectName: "project name",
  },
  {
    projectImg: Pic2,
    projectType: "design",
    projectName: "project name",
  },
];
