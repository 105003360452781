interface ITabProps {
  active: boolean;
  tabName: string;
  onClick: () => void;
}

export const Tab = ({ active, tabName, onClick }: ITabProps) => {
  return (
    <div>
      <button
        onClick={onClick}
        className={`px-5 py-3 border border-gray-200  font-medium rounded-lg   capitalize active:bg-gray-900  ${
          active
            ? "bg-gray-900 text-white hover:bg-none "
            : "text-gray-900 hover:bg-gray-300 hover:text-gray-900 "
        }`}
      >
        {tabName}
      </button>
    </div>
  );
};
