import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import React, { ReactNode, useEffect, useState } from "react";

interface ICarouselProps {
  children?: ReactNode;
}

interface ICarouselItemProps {
  children: ReactNode;
  width?: string;
}

export const CarouselItem = ({ children, width }: ICarouselItemProps) => {
  return (
    <div className="inline-flex " style={{ width: width }}>
      {children}
    </div>
  );
};

export const Carousel = ({ children }: ICarouselProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const activeTabs = React.Children;
  const [paused, setPaused] = useState<boolean>(false);
  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });
  return (
    <div
      className="flex flex-col overflow-hidden"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        className=" whitespace-nowrap  duration-300 transition transform"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child as any, { width: "100%" });
        })}
      </div>

      <div className="flex lg:justify-between justify-center  items-end">
        <div className="flex space-x-1 ">
          {activeTabs.map(children, (child, index) => (
            <div
              className={`border-2 ${
                activeIndex === index ? "border-gray-300" : "border-gray-600"
              }  w-8 rounded-lg`}
            ></div>
          ))}

          {/* <div className="border-2  lg:border-gray-600 border-gray-200 w-8 rounded-lg"></div>
          <div className="border-2  border-gray-600 w-8 rounded-lg"></div> */}
        </div>
        <div className="hidden lg:block space-y-2 ">
          <div>
            <button
              className=" border py-3 px-5 rounded-lg hover:text-black  hover:bg-gray-400 active:scale-90 transition duration-150 "
              onClick={() => {
                updateIndex(activeIndex + 1);
              }}
            >
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          </div>

          <div>
            <button
              className=" border py-3 px-5 rounded-lg hover:text-black  hover:bg-gray-400 active:scale-90 transition duration-150 "
              onClick={() => {
                updateIndex(activeIndex - 1);
              }}
            >
              <ChevronLeftIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
