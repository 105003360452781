export interface IWhatWeDoCard {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export const WhatWedoCard = ({ icon, title, description }: IWhatWeDoCard) => {
  return (
    <div className="bg-gray-500 px-6 py-5 rounded-2xl ">
      <div className="pt-8">
        {icon}
        <div className="mt-[58px]">
          <p className="font-semibold text-xl capitalize">{title}</p>
          <p className="mt-2">{description}</p>
        </div>
      </div>
    </div>
  );
};
