import { ChangeEvent, useState } from "react";
import { CustomerService, LaptopIcon } from "../assets/icons";
import { Header } from "../components/connected/header/header";
import { Footer } from "../components/sections/footer";

const { REACT_APP_BASE, REACT_APP_TOKEN } = process.env;

interface IFormDetails {
  Name: string;
  Email: string;
  Interested_Service: string;
  Phone_Number: string;
  Tell_Us_More: string;
  checkPrivacy: boolean;
}

const Contact = () => {
  const [formDetails, setFormDetails] = useState<IFormDetails>({
    Name: "",
    Email: "",
    Interested_Service: "",
    Phone_Number: "",
    Tell_Us_More: "",
    checkPrivacy: false,
  });
  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  const handleChange = (event: ChangeEvent<any>) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setFormDetails({ ...formDetails, [event.target.name]: value });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    fetch(REACT_APP_BASE as string, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${REACT_APP_TOKEN}`,
      },
      body: JSON.stringify({
        records: [
          {
            fields: {
              Name: formDetails.Name,
              Email: formDetails.Email,
              Interested_Service: formDetails.Interested_Service,
              Tell_Us_More: formDetails.Tell_Us_More,
              Phone_Number: formDetails.Phone_Number,
            },
          },
        ],
      }),
    })
      .then((res) => {
        setLoading(false);
        setFormDetails({
          Name: "",
          Email: "",
          Interested_Service: "",
          Phone_Number: "",
          Tell_Us_More: "",
          checkPrivacy: false,
        });

        return res.json();
      })
      .catch((err) => console.log(err))
      .finally(() => setSuccess(true));
  };

  return (
    <div>
      <section className="bg-blue-50 xl:px-20 px-5 md:px-8  lg:space-y-16 space-y-6 ">
        <Header variant="black" />
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-16 gap-8  lg:px-8 p-1.5 ">
          <div className="">
            <div className="lg:mb-12 mb-6">
              <h2 className="md:text-5xl text-4xl font-bold lg:mb-5 mb-2 text-center md:text-left ">
                Get In Touch
              </h2>
              <p className="text-gray-500 lg:text-xl text-sm font-normal text-center md:text-left">
                Share your project scope, timeframes, or business challenges
                you'd like to solve. Our team will carefully study them and then
                we'll figure out the next move together.
              </p>
            </div>
            <div className="flex xl:space-x-[45px] space-x-5 justify-center xl:justify-start">
              <div>
                <div className="flex md:space-x-2.5 items-center space-x-1.5  mb-1">
                  <CustomerService className="w-4 h-4 lg:w-6 lg:h-6" />
                  <p className="lg:text-xl text-xs">Customer Service</p>
                </div>
                <p className="text-gray-400 text-xs lg:text-base">
                  +2348185466356
                </p>
              </div>
              <div>
                <div className="flex md:space-x-2.5 space-x-1.5 mb-1">
                  <LaptopIcon className="w-4 h-4 lg:w-6 lg:h-6" />
                  <p className="lg:text-xl text-xs">Online Support</p>
                </div>
                <p className="text-gray-400 text-xs lg:text-base">
                  support@grapevineinnovate.com
                </p>
              </div>
            </div>
          </div>
          <form onSubmit={onSubmit}>
            <div className="xl:px-8 px-1.5 md:mb-24 mb-16 ">
              <div className="flex flex-col space-y-6 xl:p-8 p-4 bg-white border rounded-2xl shadow-md  ">
                <div className="flex flex-col space-y-1.5 w-full">
                  <p className="text-sm font-meduium">Full name</p>
                  <input
                    type="text"
                    name="Name"
                    placeholder="full name"
                    onChange={handleChange}
                    value={formDetails.Name}
                    required
                    className="px-4 py-3 outline-none rounded-lg bg-blue-50"
                  />
                </div>
                <div className="flex flex-col space-y-1.5 w-full">
                  <p className="text-sm font-meduium">Email</p>
                  <input
                    type="Email"
                    name="Email"
                    onChange={handleChange}
                    required
                    value={formDetails.Email}
                    placeholder="you@yourcompany.com"
                    className="px-4 py-3 outline-none bg-blue-50 rounded-lg"
                  />
                </div>
                <div className="flex flex-col space-y-1.5 w-full">
                  <p className="text-sm font-meduium">Interested service</p>
                  <input
                    type="text"
                    name="Interested_Service"
                    onChange={handleChange}
                    required
                    value={formDetails.Interested_Service}
                    placeholder="selected service"
                    className="px-4 py-3 outline-none bg-blue-50 rounded-lg"
                  />
                </div>
                <div className="flex flex-col space-y-1.5 w-full">
                  <p className="text-sm font-meduium">Phone number</p>
                  <input
                    type="tel"
                    name="Phone_Number"
                    pattern="[0-9]{11}"
                    onChange={handleChange}
                    required
                    value={formDetails.Phone_Number}
                    placeholder="0801000000"
                    className="px-4 py-3 outline-none bg-blue-50 rounded-lg"
                  />
                </div>
                <div className="flex flex-col space-y-1.5 w-full">
                  <p className="text-sm font-meduium">
                    Tell us more about your project.
                  </p>
                  <textarea
                    rows={5}
                    name="Tell_Us_More"
                    required
                    onChange={handleChange}
                    value={formDetails.Tell_Us_More}
                    className="px-4 py-3 outline-none rounded-lg bg-blue-50"
                  />
                </div>
                <div className="flex space-x-3">
                  <input
                    type="checkbox"
                    name="checkPrivacy"
                    onChange={handleChange}
                    checked={formDetails.checkPrivacy}
                    className="rounded-md outline-gray-400  "
                  />
                  <p className="md:text-base text-xs  text-gray-500">
                    You agree to our friendly privacy policy.
                  </p>
                </div>
                <div className="w-full">
                  <button
                    type="submit"
                    disabled={!formDetails.checkPrivacy || loading}
                    className="w-full bg-blue-600 rounded-lg text-white py-3 disabled:bg-gray-300 disabled:cursor-not-allowed"
                  >
                    {loading ? "loading.." : "Send message"}
                  </button>
                  {success ? (
                    <div className="flex justify-center text-green-500 text-sm mt-0">
                      <p>
                        Thanks for reaching out, we will be happy to work with
                        you
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Contact;
