interface IMoreDataInfo {
  infoData: string;
  infoDescription: string;
}

export const MoreInfoData = ({ infoData, infoDescription }: IMoreDataInfo) => {
  return (
    <div className="flex flex-col space-y-3 justify-between text-white">
      <h3 className="text-6xl font-semibold">{infoData}</h3>
      <p className="text-lg font-medium">{infoDescription}</p>
    </div>
  );
};
