import { cloneElement, useState } from "react";
import {
  MinusCircleIcon,
  PlusCircleIcon,
} from "../../../assets/icons/services";

interface IAccordionItem {
  isOpen?: boolean;
  hideIcon?: boolean;
  title?: React.ReactNode;
  content: React.ReactNode;
}
export const AccordionItem = ({
  title,
  content,
  //   disabledClick,
  hideIcon,
  isOpen,
}: IAccordionItem) => {
  const [open, setOpen] = useState(isOpen);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <li className="list-none">
      {!!title ? (
        <div
          onClick={toggleOpen}
          className={` flex items-center justify-between group ${
            open ? "border-none" : "border-t border-gray-200"
          }`}
        >
          <div
            className={`text-lg font-medium capitalize pt-6 ${
              open ? "pb-4" : "pb-8"
            }`}
          >
            {cloneElement(<>{title}</>, {
              isOpen: open,
            })}
          </div>
          {!hideIcon ? (
            <>{open ? <MinusCircleIcon /> : <PlusCircleIcon />} </>
          ) : null}
        </div>
      ) : null}
      {open && (
        <div
          className="text-gray-600 pb-8 w-full 
          "
        >
          {content}
        </div>
      )}
    </li>
  );
};
