import { IBlockTextProps } from "./blockText";

export const ProjectBlockText = ({ text, title }: IBlockTextProps) => {
  return (
    <div className="space-y-1">
      <p className="text-gray-400 uppercase">{title}</p>
      <p className="text-xl">{text}</p>
    </div>
  );
};
