import { Link } from "react-router-dom";
import AboutSectionImage from "../../assets/imgs/about-section.png";

export const AboutUs = () => {
  return (
    <div className="lg:py-24 py-10 lg:px-8  lg:grid grid-cols-2 w-full gap-24 block">
      <div className="flex flex-col lg:space-y-6  space-y-3  text-center lg:text-start">
        <Link to="/aboutus">
          <p className="text-blue-700 text-base font-semibold">About Us</p>
        </Link>

        <div className="flex flex-col space-y-4">
          <p className="font-semibold md:text-2xl   xl:text-3xl text-xl ">
            Our 10years working experience make a different business agency
            services.
          </p>
          <p className="text-gray-600  xl:text-lg text-sm justify-start">
            We are AVO. We create award-winning websites, remarkable brands and
            cutting-edge apps.Nullam imperdiet, sem at fringilla lobortis, sem
            nibh fringilla nibh, id gravida mi purus sit amet erat. Ut dictum
            nisi masvitp.
            <br />
            <br /> Nulla metus metus ullamcorper vel tincidunt sed euismod nibh
            volutpat velit class aptent taciti sociosqu ad litora.
          </p>
        </div>
      </div>
      <div className="hidden lg:inline-flex relative h-full   ">
        <img src={AboutSectionImage} alt="" className="rounded-2xl" />
      </div>
    </div>
  );
};
