interface ISectionHeader {
  title: string;
  description: string;
}

export const SectionHeader = ({ title, description }: ISectionHeader) => {
  return (
    <div className="lg:max-w-[768px] text-center ">
      <h2 className="font-bold lg:text-6xl text-3xl  mb-4 capitalize">
        {title}
      </h2>
      <p className="lg:text-xl text-base text-gray-600">{description}</p>
    </div>
  );
};
