import {
  DesignIcon,
  DevelopmentIcon,
  DevopsIcon,
  DigitalIcon,
} from "../assets/icons";
import { Header } from "../components/connected/header/header";
import { AccordionHeader } from "../components/controls/accordion/accordionHeader";
import { AccordionItem } from "../components/controls/accordion/accordionItem";
import { ContactForProjects } from "../components/primitives/contactForProjects";
import { Footer } from "../components/sections/footer";

const Services = () => {
  return (
    <div>
      <section className="bg-gradient-to-r from-blue-500 via-blue-400 to-blue-600 text-white xl:px-20 px-5 md:px-8 w-full ">
        <Header />
        <div className=" md:px-[72px] lg:py-[76px] px-1.5 py-8 ">
          <div className="lg:flex block justify-between  items-center  ">
            <div className="flex space-x-2 mb-4 lg:block lg:space-x-0 lg:mb-0">
              <p className="lg:text-[88px] text-5xl leading-none font-bold ">
                Our
              </p>
              <p className="lg:text-7xl text-5xl font-bold">Services</p>
            </div>
            <div className="flex max-w-[576px] lg:text-xl text-base rounded-2xl md:py-8  md:px-6 py-4 px-2  bg-gradient-to-r from-blue-400 via-blue-400 to-blue-500 ">
              <p>
                At Grapevine, We build custom software that allows businesses to
                meet their needs and constraints. We serve as a strategic
                partner, and help our clients digitally transform to improve
                their products, speed up service delivery, increase operational
                efficiency, and expand to new markets.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="xl:mx-20 mx-5 lg:py-24 py-16 md:mx-8 xl:mb-24 mb-8">
        <div className="xl:mx-8 mx-0 lg:space-y-24 space-y-16">
          <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-16 gap-8">
            <div>
              <AccordionItem
                hideIcon
                isOpen={true}
                content={
                  <AccordionHeader
                    icon={<DesignIcon />}
                    title="design"
                    description="A new website in the style of your company? Or do you prefe completely new visual identity? We bring out the unique style of your company."
                  />
                }
              />

              <AccordionItem
                isOpen={false}
                title={<p>branding </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>Digital Design </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>illustration </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p> print design</p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
            </div>
            <div>
              <AccordionItem
                hideIcon
                isOpen={true}
                content={
                  <AccordionHeader
                    icon={<DevelopmentIcon />}
                    title="development"
                    description="A new website in the style of your company? Or do you prefe completely new visual identity? We bring out the unique style of your company."
                  />
                }
              />

              <AccordionItem
                isOpen={false}
                title={<p>Mobile Development </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>Web Development </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>Product design </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
            </div>
            <div>
              <AccordionItem
                hideIcon
                isOpen={true}
                content={
                  <AccordionHeader
                    icon={<DevopsIcon />}
                    title="Devops"
                    description="A new website in the style of your company? Or do you prefe completely new visual identity? We bring out the unique style of your company."
                  />
                }
              />

              <AccordionItem
                isOpen={false}
                title={<p>Infrastructure & Operational Management </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>AI Deployments </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>Continuous Integration & Deployment </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>Code Inspection & Integration</p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
            </div>
            <div>
              <AccordionItem
                hideIcon
                isOpen={true}
                content={
                  <AccordionHeader
                    icon={<DigitalIcon />}
                    title="digital marketing & strategy"
                    description="A new website in the style of your company? Or do you prefe completely new visual identity? We bring out the unique style of your company."
                  />
                }
              />

              <AccordionItem
                isOpen={false}
                title={<p>Content Marketing </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>Email Marketing </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>Social media Marketing </p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>digital strategy</p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
              <AccordionItem
                isOpen={false}
                title={<p>SEO</p>}
                content={
                  <p>
                    Our graphic designers can help your business make great
                    impressions and deliver an impact that truly matters through
                    your names, logos, and colours.
                  </p>
                }
              />
            </div>
          </div>

          <ContactForProjects
            email="support@grapevineinnovate.com"
            telNo="+2348185466356"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Services;
