import { ReactNode } from "react";

interface IAccordionHeader {
  icon: ReactNode;
  title: string;
  description: string;
}

export const AccordionHeader = ({
  icon,
  title,
  description,
}: IAccordionHeader) => {
  return (
    <div className="space-y-5">
      <div className="text-blue-600">{icon}</div>
      <div className="w-full">
        <h6 className="text-blue-600 text-[34px] font-bold tracking-tight uppercase">
          {title}
        </h6>
      </div>
      <div>
        <p className="text-gray-600 text-xl">{description}</p>
      </div>
    </div>
  );
};
