interface IContactForProjectsProps {
  email: string;
  telNo: string;
}

export const ContactForProjects = ({
  telNo,
  email,
}: IContactForProjectsProps) => {
  return (
    <div className="flex  rounded-2xl flex-col justify-center items-center py-8 bg-blue-50 space-y-8">
      <div className="space-y-4 text-center">
        <h5 className="font-bold text-4xl">Have any project in mind?</h5>
        <p className="text-gray-600 text-lg">
          Call <strong className="text-grey=900"> {telNo}</strong> or email us
          at
          <strong className="text-grey=900">{email}</strong>
        </p>
      </div>
      <div>
        <button className="px-5 py-3 bg-blue-600 text-white rounded-lg active:scale-90 ease-in-out transition duration-150 capitalize">
          let's Discuss
        </button>
      </div>
    </div>
  );
};
