import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Header } from "../components/connected/header/header";
import { Tab } from "../components/controls/tab/tab";
import { OurWorkCard } from "../components/primitives/cards/ourWorkCard";
import { ContactForProjects } from "../components/primitives/contactForProjects";
import { Footer } from "../components/sections/footer";
import {
  allworkCardInfo,
  designCardInfo,
  developmentCardInfo,
  devOpsCardInfo,
} from "../mockdata";

const tabLists = [
  { tabName: "All", id: "all" },
  { tabName: "Design", id: "design" },
  { tabName: "Development", id: "development" },
  { tabName: "Devops", id: "devops" },
  { tabName: "Digital Marketing & Strategy", id: "digital" },
];

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState(tabLists[0].id);
  return (
    <main>
      <div className="xl:mx-20 mx-5 md:mx-8 ">
        <Header variant="black" />
      </div>
      <section className="flex justify-center text-center bg-blue-50 lg:py-24  py-10">
        <div className="lg:space-y-5 space-y-3 max-w-[768px]">
          <h2 className="lg:text-6xl font-bold text-3xl">Portfolio</h2>
          <p className="lg:text-xl text-base text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
      </section>
      <section className="flex flex-col lg:space-y-16 space-y-5 lg:py-24 py-10">
        <div className="flex lg:space-x-6 space-y-6 lg:space-y-0 justify-center flex-col mx-8 lg:flex-row ">
          {tabLists.map((tab) => (
            <Tab
              key={tab.id}
              tabName={tab.tabName}
              active={tab.id === activeTab}
              onClick={() => setActiveTab(tab.id)}
            />
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mx-8  xl:mx-28 gap-6 ">
          {activeTab === "all" && (
            <>
              {allworkCardInfo.map((card, index) => (
                <Link to={`:projectId`} key={index}>
                  <OurWorkCard
                    projectImg={card.projectImg}
                    projectType={card.projectType}
                    projectName={card.projectName}
                  />
                </Link>
              ))}
            </>
          )}
          {activeTab === "design" && (
            <>
              {designCardInfo.map((card, index) => (
                <Link to={`:projectId`} key={index}>
                  <OurWorkCard
                    projectImg={card.projectImg}
                    projectType={card.projectType}
                    projectName={card.projectName}
                  />
                </Link>
              ))}
            </>
          )}
          {activeTab === "development" && (
            <>
              {developmentCardInfo.map((card, index) => (
                <OurWorkCard
                  key={index}
                  projectImg={card.projectImg}
                  projectType={card.projectType}
                  projectName={card.projectName}
                />
              ))}
            </>
          )}
          {activeTab === "devops" && (
            <>
              {devOpsCardInfo.map((card, index) => (
                <OurWorkCard
                  key={index}
                  projectImg={card.projectImg}
                  projectType={card.projectType}
                  projectName={card.projectName}
                />
              ))}
            </>
          )}
        </div>
      </section>
      <section className="lg:pb-24 pb-10 lg:mx-28 mx-8">
        <ContactForProjects
          email="support@grapevineinnovate.com"
          telNo="+2348185466356"
        />
      </section>
      <Footer />
    </main>
  );
};
export default Portfolio;
