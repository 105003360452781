import { whatWeDoCardInfo } from "../../mockdata";
import { SectionHeader } from "../primitives/sectionHeader";
import { WhatWedoCard } from "../primitives/cards/whatWedoCard";

export const WhatWeDo = () => {
  return (
    <div className="flex flex-col lg:space-y-16 space-y-10  lg:py-24 py-10  items-center lg:px-20  px-5 bg-blue-50">
      <SectionHeader
        title="What we do"
        description="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration."
      />
      <div className="grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-6 px-8  text-white">
        {whatWeDoCardInfo.map((card, index) => (
          <WhatWedoCard
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.desciption}
          />
        ))}
      </div>
    </div>
  );
};
