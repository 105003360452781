import { Link } from "react-router-dom";
import { WhiteLogo, BlueLogo } from "../../../assets/icons/landing";
import { Bars4Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

type IHeaderVariants = "white" | "black";
interface IHeaderProps {
  variant?: IHeaderVariants;
}

export const Header = ({ variant = "white" }: IHeaderProps) => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };
  const isWhiteText = variant === "white" ? "text-white " : "text-black";
  return (
    <div>
      <header
        className={` md:py-3 p-1.5 flex items-center justify-between lg:px-8 px-1  ${isWhiteText} `}
      >
        <div className="">
          <Link to="/">
            {variant === "white" ? <WhiteLogo /> : <BlueLogo />}
          </Link>
        </div>
        <div className="hidden lg:flex items-center space-x-8 font-medium">
          <Link to="/">
            <p>Home</p>
          </Link>
          <Link to="/services">
            <p>Services</p>
          </Link>
          <Link to="/portfolio">
            <p>Portfolio</p>
          </Link>
          <Link to="/careers">
            <p>Careers</p>
          </Link>
          <button
            className={`border ${
              variant === "white" ? "" : "border-black"
            }  rounded-lg py-2.5 px-[18px] active:scale-90 transition duration-150 `}
          >
            <Link to="/contact">Get in Touch</Link>
          </button>
        </div>

        <div
          className="lg:hidden flex items-center space-x-8 font-medium"
          onClick={toggleNav}
        >
          {isNavVisible ? (
            <XMarkIcon className="w-8 h-8" />
          ) : (
            <Bars4Icon className="h-8" />
          )}
        </div>
      </header>
      {isNavVisible && (
        <div className="fixed  bg-gray-600 opacity-95  z-10 w-11/12  ">
          <div
            className={`flex flex-col space-y-4 items-center mt-4 lg:hidden ${
              variant === "white" ? "text-white" : "text-black"
            }`}
          >
            <Link to="/">
              <p>Home</p>
            </Link>
            <Link to="/services">
              <p>Services</p>
            </Link>
            <Link to="/portfolio">
              <p>Portfolio</p>
            </Link>
            <Link to="/careers">
              <p>Careers</p>
            </Link>
            <button
              className={`border ${
                variant === "white" ? "" : "border-black"
              }  rounded-lg py-2.5 px-[18px] active:scale-90 transition duration-150 `}
            >
              <Link to="/contact">Get in Touch</Link>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
