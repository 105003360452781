interface IOurWorkCard {
  projectImg: string;
  projectType: string;
  projectName: string;
  viewProjectBtn?: boolean;
  showShadow?: boolean;
}

export const OurWorkCard = ({
  projectImg,
  projectType,
  projectName,
  viewProjectBtn,
  showShadow,
}: IOurWorkCard) => {
  return (
    <div className={`relative  ${showShadow ? "shadow-sm rounded-2xl" : ""}  `}>
      <img src={projectImg} alt="" />
      <div className="p-6 flex justify-between items-end ">
        <div className="flex flex-col justify-between">
          <p className="text-gray-400 lg:text-sm text-sx mb-2 capitalize">
            {projectType}
          </p>
          <p className="font-medium lg:text-xl text-lg capitalize">
            {projectName}
          </p>
        </div>
        {viewProjectBtn && (
          <div>
            <button className="lg:py-2.5 lg:px-4 py-2 px-2 text-sm lg:text-base  border font-medium rounded-md active:scale-90 transition duration-150">
              View project
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
