import { SectionHeader } from "../primitives/sectionHeader";

import { OurWorkCard } from "../primitives/cards/ourWorkCard";
import { ourworkCardInfo } from "../../mockdata";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";

export const OurWork = () => {
  return (
    <div className="flex flex-col lg:space-y-16 space-y-10 lg:py-24 py-10 items-center">
      <SectionHeader
        title="Our Work"
        description="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration."
      />
      <div>
        <div className="grid grid-cols-1  md:grid-cols-2 xl:grid-cols-3 gap-6 px-8 ">
          {ourworkCardInfo.map((card, index) => (
            <OurWorkCard
              key={index}
              projectImg={card.projectImg}
              projectType={card.projectType}
              projectName={card.projectName}
              viewProjectBtn={true}
              showShadow={true}
            />
          ))}
        </div>
        <div className="mt-12 px-8 flex justify-end  items-center space-x-2 text-blue-700 text-xl font-semibold active:scale-90 transition duration-150 cursor-pointer ">
          <span className="">See all works</span>

          <ArrowSmallRightIcon width={24} height={24} />
        </div>
      </div>
    </div>
  );
};
