import HaveAProjectImg from "../../assets/imgs/have-a-project.png";

export const HaveAProject = () => {
  return (
    <div className="grid grid-col-1 lg:grid-cols-2 md:mx-20 mx-5 md:px-8  px-2 gap-16">
      <div className="md:px-12 px-5 md:space-y-12 space-y-6">
        <div className="md:space-y-5 space-y-2 ">
          <h2 className="font-bold md:text-5xl text-4xl">Have a Project?</h2>
          <p className="md:text-xl text-base text-gray-500">
            Tell us more about your project
          </p>
        </div>
        <form>
          <div className="flex flex-col space-y-6 ">
            <div className="flex flex-col space-y-1.5 w-full">
              <p className="text-sm font-meduium">Full name</p>
              <input
                type="text"
                placeholder="full name"
                className="px-4 py-3 outline-none rounded-lg"
              />
            </div>
            <div className="flex flex-col space-y-1.5 w-full">
              <p className="text-sm font-meduium">Email</p>
              <input
                type="Email"
                placeholder="you@yourcompany.com"
                className="px-4 py-3 outline-none rounded-lg"
              />
            </div>
            <div className="flex flex-col space-y-1.5 w-full">
              <p className="text-sm font-meduium">Interested service</p>
              <input
                type="text"
                placeholder="selected service"
                className="px-4 py-3 outline-none rounded-lg"
              />
            </div>
            <div className="flex flex-col space-y-1.5 w-full">
              <p className="text-sm font-meduium">Phone number</p>
              <input
                type="tel"
                placeholder="+1 (555) 000-0000"
                className="px-4 py-3 outline-none rounded-lg"
              />
            </div>
            <div className="flex flex-col space-y-1.5 w-full">
              <p className="text-sm font-meduium">
                Tell us more about your project.
              </p>
              <textarea
                rows={5}
                className="px-4 py-3 outline-none rounded-lg"
              />
            </div>
            <div className="flex space-x-3">
              <input
                type="checkbox"
                className="rounded-md outline-gray-400  "
              />
              <p className="md:text-base text-sm  text-gray-500">
                You agree to our friendly privacy policy.
              </p>
            </div>
            <button className="bg-blue-600 rounded-lg text-white py-3">
              Send message
            </button>
          </div>
        </form>
      </div>
      <div className="hidden lg:inline-flex ralative ">
        <img src={HaveAProjectImg} alt="" />
      </div>
    </div>
  );
};
