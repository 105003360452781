import { Header } from "../components/connected/header/header";
import HeroImage from "../assets/imgs/Hero-section.png";
import { AboutUs } from "../components/sections/aboutUs";
import { WhatWeDo } from "../components/sections/whatWeDo";
import { OurWork } from "../components/sections/ourWorks";
import { MoreInfo } from "../components/sections/moreInfo";
import { Hero } from "../components/sections/hero";
import { HaveAProject } from "../components/sections/haveAProject";

import { Footer } from "../components/sections/footer";

const LandingPage = () => {
  return (
    <main>
      <section
        className="px-5 lg:px-28 lg:pb-24 pb-5"
        style={{
          background: `url(${HeroImage})`,
          width: "100%",
          height: "auto",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Header />
        <div className=" lg:mt-24 mt-5 w-full ">
          <Hero />
        </div>
      </section>
      {/* <div
        className="md:px-8 px-5 relative md:h-[440px]  xl:px-20 lg:h-[540px] h-[340px] xl:h-[540px] 2xl:h-[640px]"
        style={{
          background: `url(${HeroImage})`,
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      > */}
      {/* <section>
        </section> */}
      <section className="md:mx-8 mx-5  lg:mx-20">
        <AboutUs />
      </section>
      <section>
        <WhatWeDo />
      </section>
      <section className="md:mx-8 mx-5  lg:mx-20">
        <OurWork />
      </section>
      <section className="bg-blue-700 lg:py-24 py-10 flex justify-center">
        <MoreInfo />
      </section>
      <section className="bg-blue-50 md:pt-16 md:pb-20 pt-8 pb-10">
        <HaveAProject />
      </section>
      <Footer />
      {/* </div> */}
    </main>
  );
};

export default LandingPage;
