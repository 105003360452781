import { Link } from "react-router-dom";
import { WhiteLogo } from "../../assets/icons";

export const Footer = () => {
  return (
    <footer className="lg:px-20  px-8 pt-20 pb-12 bg-gray-900 text-white">
      <div className="px-8 grid lg:grid-cols-4 grid-cols-1  md:grid-cols-3 lg:gap-16 gap-9 mb-16">
        <div className="space-y-8 md:col-span-3 lg:col-span-1 md:place-self-center">
          <Link to="/">
            <WhiteLogo />
          </Link>
          <div>
            <p className="text-sm ">
              We build custom software that allows businesses to meet their
              needs and constraints.
            </p>
          </div>
        </div>
        <div className="font-inter max-w-[130px]">
          <p className="text-sm font-semibold text-gray-400 mb-4">Company</p>
          <div className="flex flex-col space-y-3 font-medium ">
            <Link to="/">About Us</Link>
            <Link to="/">Portfolio</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Contact</Link>
          </div>
        </div>
        <div className="font-inter max-w-[130px]">
          <p className="text-sm font-semibold text-gray-400 mb-4">Services</p>
          <div className="flex flex-col space-y-3 font-medium ">
            <Link to="/">Design</Link>
            <Link to="/">Development</Link>
            <Link to="/">Devops</Link>
            <Link to="/">Digital Markeing & Strategy</Link>
          </div>
        </div>
        <div className="font-inter max-w-[130px]">
          <p className="text-sm font-semibold text-gray-400 mb-4">Social</p>
          <div className="flex flex-col space-y-3 font-medium ">
            <Link to="/">Twitter</Link>
            <Link to="/">Linkedin</Link>
            <Link to="/">Facebook</Link>
            <Link to="/">Github</Link>
          </div>
        </div>
      </div>
      <div className="border-t pt-9 border-gray-600 text-gray-600">
        <p>© 2022 grapevineinnovate ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};
