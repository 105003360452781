import { Carousel, CarouselItem } from "../controls/carousel/carousel";

export const Hero = () => {
  return (
    <div className="text-white space-y-11 text-center lg:text-left  ">
      <Carousel>
        <CarouselItem>
          <div>
            <div className="lg:px-32 px-0 ">
              <div className="flex flex-col xl:space-y-8 md:space-y-4 space-y-2">
                <p className="uppercase md:text-xl text-base font-normal tracking-widest">
                  Original
                </p>
                <h3 className="md:text-[88px] md:leading-[72px] text-4xl font-black  tracking-widest capitalize">
                  Solutions
                </h3>
                <p className="md:text-xl  max-w-full lg:max-w-[560px]    text-xs font-normal">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Volutpat massa lacus, id viverra aliquet.
                </p>
                <div>
                  <button className=" font-medium bg-blue-700 md:px-5 md:py-3  px-3 py-2 rounded-md md:rounded:lg active:scale-90 transition duration-150 ">
                    Discover work
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div>
            <div className="lg:px-32 px-0 ">
              <div className="flex flex-col xl:space-y-8 md:space-y-4 space-y-2">
                <p className="uppercase md:text-xl text-base font-normal tracking-widest">
                  Original
                </p>
                <h3 className="md:text-[88px] md:leading-[72px] text-4xl font-black  tracking-widest capitalize">
                  Problems
                </h3>
                <p className="md:text-xl  max-w-full lg:max-w-[560px]    text-xs font-normal">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Volutpat massa lacus, id viverra aliquet.
                </p>
                <div>
                  <button className=" font-medium bg-blue-700 md:px-5 md:py-3  px-3 py-2 rounded-md md:rounded:lg active:scale-90 transition duration-150 ">
                    Discover work
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div>
            <div className="lg:px-32 px-0 ">
              <div className="flex flex-col xl:space-y-8 md:space-y-4 space-y-2">
                <p className="uppercase md:text-xl text-base font-normal tracking-widest">
                  Original
                </p>
                <h3 className="md:text-[88px] md:leading-[72px] text-4xl font-black  tracking-widest capitalize">
                  Solutions
                </h3>
                <p className="md:text-xl  max-w-full lg:max-w-[560px]    text-xs font-normal">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Volutpat massa lacus, id viverra aliquet.
                </p>
                <div>
                  <button className=" font-medium bg-blue-700 md:px-5 md:py-3  px-3 py-2 rounded-md md:rounded:lg active:scale-90 transition duration-150 ">
                    Discover work
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  );
};
